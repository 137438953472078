body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto 0;
  overflow-x: hidden;
}

h1 {
  margin: 0;
}

.background-container {
  background-color: #595969;
  width: 100vw;
  height: 100%;
  min-height: 100vw;
}

::placeholder {
  color: white;
  font-size: 1.1rem;
}

.signup-btn {
  width: 98%;
  margin: 0px !important;
  margin-top: 0.5rem !important;
}

.application-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1000px) {
  .login-container {
    min-height: 200vw;
  }
  .form-container {
    min-height: 130vw;
  }
}

.burn-loader-login {
  margin-left: 4rem;
}

.loading-text {
  color: white;
}

.todo-input {
  font-size: 1.1rem;
}

.todo-list-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.todo-card {
  width: 18rem;
  margin: 1rem;
}

.description {
  display: flex;
  justify-content: center;
}

.text {
  width: 93%;
  padding-left: 1rem;
}

.close {
  background-image: url(https://i.imgur.com/iviMK5g.png);
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  background-position: right 3px top 3px;
  width: 7%;
  margin-bottom: 23%;
  padding-left: 1%;
}

@media (max-width: 1220px) {
  .close {
    margin-bottom: 18%;
    padding-left: 1%;
  }
}

@media (max-width: 780px) {
  .close {
    margin-bottom: 14%;
    padding-left: 1%;
  }
}

.close:hover {
  cursor: pointer;
}

.img {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
